@import 'typefaces';

$title-font: 'Poppins', sans-serif;
$content-font: 'Roboto', sans-serif;
$code-font: 'Fira Code', monospace;

$background: white;
$background-dark: darken(white, 5%);
$hover-background-color: darken($background, 7.5%);
$active-background-color: darken($background, 10%);
$color: #333;
$black: #333;
$primary-green: darken(#9bd600, 20%);
$primary: #ffc410;
$border-color: darken($background, 15%);

$red: #FF455E;
$orange: #F7B500;

html, body {
  margin: 0;
  padding: 0;
  font-family: $content-font;

  canvas {
    outline: none;
  }
  
  main.page-wrapper {
    *{
      transition: 120ms;
    }
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: $background;
    color: $color;
    
    @import 'components/main', 'layout/main', 'routes/main';

    &.dark {
      // Variables
      $background: #3a484d;
      $hover-background-color: lighten($background, 5%);
      $active-background-color: lighten($background, 10%);
      $background-dark: darken(desaturate(adjust-hue($background, -2), 0.39), 7.84);
      $color: white;
      $primary-green: #9bd600;
      $border-color: lighten($background, 15%);


      
      color: $color;
      background: $background;
      @import 'components/main', 'layout/main', 'routes/main';
    }
  }
}

div.vertical-split {
  display: grid;
  grid-template-columns: 1fr 1fr;
}