div.carouselParent {
  div.carouselView {
    height: 100%;
    width: 100%;
    div.carousel {
      height: 100%;
      div.slider-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        width: calc((100vw - 56px) / 2);
        ul.slider {
          height: 100%;
          align-items: center;
          li {
            height: 100%;
            width: calc((100vw - 56px) / 2);
            div.image-wrapper {
              height: 100%;
              width: calc((100vw - 56px) / 2);
              height: calc(100vh - 73px);
              position: relative;
              canvas {
                width: calc((100vw - 56px) / 2);
                height: 100%;
              }
              img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: block;
                pointer-events: none;
                opacity: 0;
              }
            }
          }
        }
        div.carouselItem {
          height: 100%;
          div.carouselData {
            width: calc((100vw - 56px) / 2);
            height: 100%;
          }
        }
      }
    }
  }
}