div.home-wrapper {
  height: calc(100vh - 73px);
  display: flex;
  align-items: center;
  justify-content: center;
  div.options-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 0 48px;
    gap: 48px;
    a.option-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      color: $color;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        div.outline::after {
          top: -8px !important;
          left: -8px !important;
          bottom: -8px !important;
          right: -8px !important;
        }
      }
      div.image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        div.outline {
          position: absolute;
          padding-top: 100%;
          width: 100%;
          &::after {
            position: absolute;
            display: block;
            transition: 120ms;
            content: '';
            background-color: rgba($background-dark, .5);
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            border-radius: 100%;
          }
        }
        svg {
          position: relative;
          z-index: 12;
          max-width: 100%;
        }
      }
      h2 {
        font-family: $title-font;
        text-align: center;
        font-weight: 400;
        font-size: 20px;
        margin: 0;
        margin-top: 24px;
        text-transform: capitalize;
      }
    }
  }
}