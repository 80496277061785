div.content-segment-wrapper {
  &:not(:last-child) {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
  h2 {
    font-family: $title-font;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    opacity: .64;
    margin: 0;
  }
  div.content {
    margin-top: 12px;
  }
}