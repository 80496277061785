button.button-wrapper {
  margin-bottom: 12px;
  padding: 10px 20px;
  font-family: $title-font;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  border-radius: 5px;
  background-color: $background-dark;
  user-select: none;
  border: none;
  color: $color;
  outline: none;
  span {
    text-align: center;
    display: block;
    flex: 1;
  }
  &.primary {
    background-color: $primary;
    color: $black;
    font-weight: 500;
  }
  &.destructive {
    background-color: $red;
    color: white;
    font-weight: 500;
    &:focus {
      box-shadow: 0 0 0 1px rgba($red, 1), 0 0 0 4px rgba($red, .4);
    }
  }
  svg {
    &:first-child {
      margin-right: 12px;
    }
    &:last-child {
      margin-left: 12px;
    }
  }
  &:focus {
    box-shadow: 0 0 0 1px rgba($primary, 1), 0 0 0 4px rgba($primary, .4);
  }
}