div.calendar-input-outer-wrapper {
  width: 320px;
  max-width: 100%;
  div.calendar-wrapper {
    background-color: $background;
    border-radius: 4px;
    border: 1px solid $border-color;
    padding: 12px;
    button {
      font-family: $title-font;
      color: $color;
    }
    div.react-calendar__month-view__days {
      display: grid;
      grid-template-columns: repeat(1fr, 7);
      margin: auto;
      width: 320px;
      button {
        max-width: 100% !important;
      }
    }
    button.react-calendar__tile {
      width: 32px;
      height: 32px;
      display: block;
      border: none;
      background: none;
      cursor: pointer;
      flex-grow: 0;
      flex-shrink: 0;
      border-radius: 4px;
      margin: 2px;
      outline: none;
      &:hover {
        background: $background-dark;
      }
      &:active {
        background: darken($background-dark, 5%);
      }
    }
  }
}