div.slider-outer-wrapper {
  width: 320px;
  padding: 0 12px;
  padding-bottom: 24px;
  div.labels {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 16px;
    span {
      left: 0;
      transform: translateX(-50%);
      position: absolute;
      &:last-child {
        right: 0;
        transform: translateX(50%);
        left: unset;
      }
    }
  }
  div.slider-input-wrapper {
    display: flex;
    align-items: center;
    div.line {
      position: relative;
      height: 4px;
      flex: 1;
      background-color: $background-dark;
      border-radius: 5px;
      div.highlight {
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: $primary;
        border-radius: 5px;
        transition: none;
      }
    }
  
    div.knob-wrapper {
      user-select: none;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 12;
      transition: none;
      &:hover, &.active {
        div.knob {
          box-shadow: 0 0 0 10px rgba($border-color, .4);
        }
        div.knob-label {
          opacity: 1;
          visibility: visible;
          transform: translate(-50%, -100%);
        }
      }
      div.knob {
        width: 14px;
        height: 14px;
        border-radius: 500px;
        background-color: $color; 
        cursor: pointer;
        
      }
      div.knob-label {
        position: absolute;
        pointer-events: none;
        top: -12px;
        transform: translate(-50%, calc(-100% + 8px));
        display: flex;
        padding: 4px 12px;
        white-space: nowrap;
        left: 50%;
        background: $background;
        border: 1px solid $border-color;
        border-radius: 3px;
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}