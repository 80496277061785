div.progress-wrapper {
  display: flex;
  align-items: center;
  span.label {
    margin-left: 12px;
    font-weight: 500;
    font-size: 14px;
  }
  div.bar-container {
    flex: 1;
    height: 24px;
    background-color: $background-dark;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    div.bar {
      background: $primary;
      height: 100%;
      transition: 120ms;
    }
    span.label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      font-weight: 600;
      font-family: $title-font;
    }
  }
}