div.vertical-tabs-wrapper {
  width: 64px;
  background: $background;
  border-right: 1px solid $border-color;
  display: block;
  div.tab-spacer {
    height: 8px;
    background-color: $background-dark;
    border-bottom: 1px solid $border-color;
  }
  a.vertical-tab-wrapper {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-bottom: 1px solid $border-color;
    color: rgba($color, .8);
    &.link.active, &.force-active {
      color: $primary;
      background-color: $background-dark;
    }
    &.disabled {
      pointer-events: none;
      color: transparentize($color, .8);
    }
  }
}