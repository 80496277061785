div.view-options-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 320px;
  min-width: 210px;
  grid-gap: 8px;
  div.view-option-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 8px;
    position: relative;
    cursor: pointer;
    padding-bottom: 32px;
    border-radius: 8px;
    &:hover {
      background-color: $hover-background-color;
    }
    &:active {
      background-color: $active-background-color;
    }
    &.active {
      color: $primary;
      background-color: $background-dark;
      div.image-wrapper {
        img {
          border-color: $primary;
        }
      }
    }
    &.disabled {
     opacity: 0.2;
     pointer-events: none;
    }
    div.image-wrapper {
      padding-top: 100%;
      position: relative;
      box-sizing: border-box;
      img {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: block;
        background-color: $background-dark;
        border-radius: 5px;
        border: 1px solid $border-color;
        object-fit: cover;
      }
    }
    span {
      white-space: nowrap;
      width: 100%;
      position: absolute;
      text-overflow: ellipsis;
      bottom: 24px;
      left: 50%;
      transform: translateY(100%) translateX(-50%);
      text-align: center;
      font-size: 12px;
      font-family: $title-font;
      font-weight: 500;
    }
    // img {
    //   width: 120px;
    //   height: 120px;
    //   background-color: red;
    //   display: block;
    // }
  }
}