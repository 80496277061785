div.popup-wrapper {
  position: relative;
  &:hover div.popup {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 12px);
    transition-delay: 640ms;
  }
  &.right {
    &:hover div.popup {
      transform: translate(calc(100% + 12px), -50%);
    }
    div.popup {
      left: unset;
      right: 0;
      top: 50%;
      transform: translate(calc(100%), -50%);
    }
  }
  &.top {
    &:hover div.popup {
      transform: translate(calc(-50%), calc(-40% - 2px));
    }
    div.popup {
      left: 50% !important;
      top: 0%;
      translate: transform(-50%, -40%);
    }
  }
  div.popup {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: fit-content;
    pointer-events: none;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    font-family: $title-font;
    font-size: 14px;
    background-color: $background;
    border: 1px solid $border-color;
    padding: 6px 16px;
    border-radius: 4px;
    transition: 320ms;
    z-index: 128;
    span.line {
      display: block;
    }
  }
}