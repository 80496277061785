div.loader-container-wrapper {
  flex: 1;
  display: flex;
  position: relative;
  div.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 24;
    background-color: rgba(black, .6);
    backdrop-filter: blur(2px);
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
}