div.loader-wrapper {
  padding: 64px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  svg {
    width: 100px;
    height: 100px;
    animation: turning 1s linear infinite;
    path {
      fill: $color;
    }
  }

  @keyframes turning {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}