nav {
  padding: 0;
  margin: 0;
  box-sizing: content-box;
  background: $background-dark;
  display: flex;
  border-bottom: 1px solid $border-color;
  display: flex;
  align-items: center;
  position: relative;
  font-family: $title-font;
  div.title-wrapper {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  div.actions-wrapper {
    margin-left: auto;
    display: flex;
    div.action-wrapper {
      height: 72px;
      width: 72px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
    }
  }
  div.logo-wrapper {
    display: flex;
    align-items: center;
    padding: 0 24px;
    height: 72px;
    user-select: none;
    cursor: pointer;
    svg {
      margin-right: 12px;
      width: 42px;
      height: 42px;
      &:first-child g {
        fill: $color;
      }
    }
    div.caption {
      hr {
        margin: 8px 0;
        background-color: $color;
        opacity: .4;
        border: none;
        height: 1px;
      }
      h1, h2 {
        margin: 0;
        font-size: 20px;
        font-weight: initial;
        line-height: 20px;
      }
      h1 {
        color: $primary-green;
      }
      h2 {
        font-size: 14px;
        opacity: .8;
      }
    }
  }
}