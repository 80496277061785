div.input-wrapper {
  height: 48px;
  background-color: $background-dark;
  width: 320px;
  max-width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: 320ms;
  box-shadow: 0 0 0 1px $border-color, 0 0 0 0px rgba($primary, 0);
  margin-bottom: 32px;
  position: relative;
  // overflow: hidden;
  &.error {
    box-shadow: 0 0 0 1px $red, 0 0 0 0px rgba($primary, 0);
    span.alert {
      color: $red;
    }
  }
  &.warning {
    box-shadow: 0 0 0 1px $orange, 0 0 0 0px rgba($primary, 0);
    span.alert {
      color: $orange;
    }
  }
  &.disabled {
    pointer-events: none;
    svg, input {
      opacity: .4;
    }
  }
  &.focused {
    // background-color: transparent;
    box-shadow: 0 0 0 1px rgba($primary, 1), 0 0 0 4px rgba($primary, .4);
  }
  &.icon {
    input {
      padding-left: 44px;
    }
  }
  svg {
    margin: 0 12px;
    position: absolute;
    pointer-events: none;
    width: 20px;
    height: 20px;
  }
  input {
    width: 100%;
    height: 100%;
    background: none;
    outline: none;
    border: none;
    font-size: 16px;
    color: $color !important;
    font-family: $title-font;
    padding-left: 12px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    background: none;
    color: $color !important;
    -webkit-text-fill-color: $color !important;
    box-shadow: 0 0 0 30px $background-dark inset !important;
  }
  span.alert {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    padding: 6px 12px;
    font-family: $title-font;
    font-size: 14px;
  }
}