div.table-wrapper {
  font-size: 14px;
  height: 100%;
  overflow: auto;
  table {
    width: 100%;
    text-align: left;
    thead {
      tr:first-child {
        background-color: $background-dark;
      }
      th {
        border-bottom: 1px solid $border-color;
        div.title-wrapper {
          font-family: $title-font;
          font-weight: 500;
          font-size: 16px;
          padding: 16px 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          &:hover {
            background-color: transparentize($hover-background-color, 0.0);
          }
          &:active {
            background-color: transparentize($active-background-color, 0.0);
          }
          div.sort-wrapper {
            display: flex;
            align-items: center;
          }
        }
      }
    }
    tr {
      user-select: none;
      &:nth-child(2n) {
        background-color: transparentize($background-dark, 0.7);
      }
      &.clickable {
        cursor: pointer;
        &:hover {
          background: transparentize($background-dark, .2);
        }
        &:active {
          background: transparentize($background-dark, 0);
        }
      }
      td {
        padding: 12px 24px;
      }
      &.selected {
        td {
          color: $primary;
        }
      }
    }
  }
  pre.code {
    font-family: $code-font;
    padding: 4px 12px;
    border-radius: 3px;
    background: $background-dark;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }

  span.title {
    font-size: 16px;
    font-family: $title-font;
    font-weight: 500;
  }

  div.status-wrapper {
    display: flex;
    align-items: center;
    div.status-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      border-radius: 50px;
      background-color: $background-dark;
      &.PENDING {
        background-color: lightblue;
      }
      &.FAILED, &.ERROR {
        background-color: red;
      }
      &.INPROGRESS {
        background-color: pink;
      }
      &.COMPLETE {
        background-color: lightgreen;
      }
    }
    span.label {
      text-transform: capitalize;
    }
  }

  button {
    width: 32px;
    height: 32px;
    background: transparent;
    &:hover {
      background-color: $background-dark;
      color: $red;
    }
    &:active {
      background-color: darken($background-dark, 10%);
    }
    border: none;
    outline: none;
    cursor: pointer;
    color: $color;
    border-radius: 5px;
    display: block;
    margin-left: auto;
  }
}