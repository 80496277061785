div.info-wrapper {
  padding: 12px;
  form.entry-wrapper {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    &.vertical {
      flex-direction: column;
      span.value {
        margin-top: 4px;
      }
    }
    &.active {
      background-color: $background-dark;
    }
    &.button {
      &:not(.active):hover {
        background-color: $hover-background-color;
      }
      &:not(.active):active {
        background-color: $active-background-color;
      }
      cursor: pointer;
      padding: 12px;
      margin: 0;
      border-radius: 4px;
    }
    span, input.value {
      white-space: nowrap;
      overflow: hidden;
      flex: 1;
      text-overflow: ellipsis;
      &.label {
        font-family: $title-font;
      }
      &.value {
        opacity: .72;
      }
      &:first-child {
        padding-right: 12px;
      }
    }
    input {
      background: none;
      outline: none;
      border: none;
      color: $color;
      font-family: $title-font;
      padding: 4px 12px;
      border-radius: 3px;
      border: 1px solid transparent;
      &:not(:disabled) {
        border: 1px solid $border-color;
        background-color: darken($background, 5%);
      }
    }

    input.submit {
      appearance: none;
      display: none;
    }
  }
}