div.mobile-overlay {
  @media screen and (min-width: 700px) {
    display: none !important;
  }
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2056;
  background: $background;
  padding: 24px;
  svg {
    margin-bottom: 12px;
  }
  h1, h2 {
    margin: 12px 0;
    font-weight: 500;
    font-family: $title-font;
    text-align: center;
    font-size: 18px;
  }
  h1 {
    font-size: 24px;
  }
}