div.checkbox-input-wrapper {
  height: 48px;
  width: 320px;
  max-width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: 320ms;
  margin-bottom: 12px;
  position: relative;
  font-family: $title-font;
  cursor: pointer;
  user-select: none;
  padding: 0 12px;
  span.alert {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    padding: 6px 12px;
    font-family: $title-font;
    font-size: 14px;
  }
  span.label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.active {
    div.knob {
      opacity: 1 !important;
    }
  }
  &.disabled {
    opacity: .4;
    pointer-events: none;
  }
  div.checkbox-wrapper {
    height: 24px;
    width: 24px;
    border-radius: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    border: 2px solid $color;
    flex-shrink: 0;
    div.knob {
      width: 12px;
      height: 12px;
      border-radius: 50px;
      background: $color;
      opacity: 0;
    }
  }
}