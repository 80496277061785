div.map-outer-wrapper {
  flex: 1;
  position: relative;
  border-left: 1px solid $border-color;
  div.map-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

div.mapboxgl-popup-content {
  background: $background;
  border: 1px solid $border-color;
  z-index: 1024;
  box-sizing: border-box;
}

div.mapboxgl-popup-tip {
  background: $background;
  border: none;
  position: absolute;
  width: 10px !important;
  height: 10px !important;
  border-bottom: 1px solid $border-color;
  border-right: 1px solid $border-color;
  z-index: 1025;
  border-bottom-right-radius: 3px;
}

div.mapboxgl-popup-anchor-bottom div.mapboxgl-popup-tip{
  bottom: 0;
  transform: translateY(calc(50% - 0.5px)) rotate(45deg);
}

div.mapboxgl-popup-anchor-top div.mapboxgl-popup-tip{
  transform: translateX(-50%) translateY(calc(-50% + 0.5px)) rotate(-135deg);
  left: 50%
}
div.mapboxgl-popup-anchor-top-right div.mapboxgl-popup-tip,
div.mapboxgl-popup-anchor-bottom-right div.mapboxgl-popup-tip,
div.mapboxgl-popup-anchor-top-top div.mapboxgl-popup-tip,
div.mapboxgl-popup-anchor-bottom-top div.mapboxgl-popup-tip {
  display: none;
}

div.mapboxgl-popup-anchor-left div.mapboxgl-popup-tip{
  transform: translateX(calc(-50% + 0.5px)) rotate(-225deg);
}
div.mapboxgl-popup-anchor-right div.mapboxgl-popup-tip{
  transform: translateX(calc(50% - 0.5px)) rotate(-45deg);
}

div.mapboxgl-popup, div.mapboxgl-popup-content, div.mapboxgl-popup-content * {
  transition: none;
}

div.mapboxgl-ctrl-bottom-left div.mapboxgl-ctrl {
  display: none;
}
div.mapboxgl-ctrl.mapboxgl-ctrl-scale {
  position: absolute;
  bottom: 38px;
  left: 8px;
}
div.map-controls {
  padding: 12px;
  div.toggle-input-wrapper {
    width: calc(100% - 24px);
  }
}

div.map-controls-wrapper {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 12;
  div.action-group {
    margin-bottom: 8px;
    border-radius: 3px;
    padding: 2px;
    div.action-wrapper {
      background: $background;
      border: 1px solid $border-color;
      font-size: 14px;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      cursor: pointer;
      &.active {
        color: $primary;
      }
      &:not(:last-child) {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:not(:first-child) {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      &:hover {
        background: $hover-background-color;
      }
      &:active {
        background: $active-background-color;
      }
    }
  }
}

div.map-actions-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  button {
    pointer-events: all;
    margin: 0;
  }
}

div.controls-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
div.sidebar-map-controls-wrapper {
  width: 100%; 
  height: 100%;
  overflow-y: auto;
  padding: 12px;
  box-sizing: border-box;
  div.toggle-input-wrapper, div.checkbox-input-wrapper, div.input-wrapper {
    width: unset;
    box-sizing: border-box;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.mapboxgl-ctrl-attrib {
  display: none;
}

div.powered {
  position: absolute;
  top: 12px;
  right: 12px;
  font-weight: 500;
  font-family: $title-font;
  display: flex;
  align-items: center;
  font-size: 10px;
  svg {
    height: 16px;
    width: 16px;
    margin-left: 6px;
    path {
      fill: $color;
    }
  }
}

div.custom-popup-wrapper {
  min-width: 160px;
  box-sizing: border-box;
  position: relative;
  button {
    width: 100%;
    margin-bottom: 0;
    margin-top: 24px;
  }
  div.close-wrapper {
    position: absolute;
    top: -12px;
    right: -12px;
    padding: 8px 12px;
    cursor: pointer;
    z-index: 12;
  }
  div.detail-wrapper {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    span {
      display: block;
      font-family: $title-font;
      font-size: 14px;
      &.label {
        margin-bottom: 0px;
        text-transform: uppercase;
        opacity: .6;
      }
      &.value {
        font-size: 16px;
      }
    }
  }
  span.empty {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    font-family: $title-font;
  }
}