$tree-height: 140px;

div.matching-wrapper {
  display: flex;
  width: 100%;
  padding: 24px;
  padding-right: 0;
  box-sizing: border-box;
  user-select: none;
  border-top: 1px solid $border-color;
  span.title {
    font-family: $title-font;
    margin-bottom: 12px;
    display: block;
    font-size: 16px;
    font-weight: 500;
  }
  div.source-wrapper {
    position: relative;
    &::before {
      height: $tree-height;
      width: 1px;
      background-color: $border-color;
      display: block;
      content: '';
      position: absolute;
      bottom: 3px;
      right: 0px;
    }
  }
  div.matches-wrapper {
    width: 100%;
    padding-left: 24px;
    position: relative;
    overflow-x: auto;
    overflow-y: visible;
    div.match-container {
      display: flex;
      flex: 1;
    }
  }

  span.empty {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(calc(-50% + 12px));
    font-size: 24px;
    font-family: $title-font;
  }

  div.tree-outer-wrapper {
    margin-right: 24px;
    &.no-match {
      div.tree-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        span.title {
          margin: 0;
        }
      }
    }
    div.tree-wrapper {
      width: 200px;
      height: $tree-height;
      border-radius: 5px;
      border: 1px solid $border-color;
      flex-shrink: 0;
      box-sizing: border-box;
      background: $background-dark;
      padding: 12px;
      &.primary {
        border-color: $primary-green;
      }
      &.active {
        border-color: $primary;
      }
      &.selectable {
        cursor: pointer;
        &:hover {
          background-color: darken($background-dark, 3%);
        }
        &:active {
          background-color: darken($background-dark, 7%);
        }
      }
  
      div.header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        span.title {
          margin: 0;
          font-family: $title-font;
          font-weight: 500;
        }
      }
  
      div.detail-wrapper {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        span.label {
          font-family: $title-font;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        span.value {
          opacity: .64;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

div.dbmatch-controls {
  padding: 24px;
  span.title {
    display: block;
    font-family: $title-font;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    
    margin-bottom: 24px;
    opacity: .4;
  }
  div.caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    span {
      display: block;
      font-family: $title-font;
      &.label {
        opacity: 0.4;
        text-transform: uppercase;
        font-size: 14px;
      }
    }
  }
  div.slider-outer-wrapper {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 24px;
  }
  button {
    width: 100%;
    span.label {
      color: white;
    }
  }
}