div.toggle-input-wrapper {
  height: 48px;
  width: 320px;
  max-width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  position: relative;
  font-family: $title-font;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  padding: 0 12px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  span.label {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  span.alert {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    padding: 6px 12px;
    font-family: $title-font;
    font-size: 14px;
  }
  &.active {
    div.toggle-wrapper {
      background: $primary;
      div.knob {
        transform: translateX(18px);
      }
    }
  }
  &.disabled {
    opacity: .32;
    pointer-events: none;
  }
  div.toggle-wrapper {
    height: 24px;
    width: 42px;
    background-color: $background-dark;
    border-radius: 50px;
    padding: 2px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    div.knob {
      width: 20px;
      height: 20px;
      border-radius: 50px;
      background: white;
      box-shadow: 0 0 12px 0 rgba(black, .1);
    }
  }
}