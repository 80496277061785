div.color-input-wrapper {
  height: 48px;
  width: 320px;
  max-width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  position: relative;
  font-family: $title-font;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  padding: 0 12px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  span.label {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.disabled {
    opacity: .4;
    pointer-events: none;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
  div.color {
    width: 24px;
    height: 24px;
    border-radius: 5px;
  }
}