div.login-wrapper {
  flex: 1;
  background-color: $background-dark;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  form {
    border: 1px solid $border-color;
    background-color: $background;
    padding: 32px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-top: 96px;
    position: relative;
    z-index: 2;
    & > button {
      width: 100%;
      margin: 0;
      span {
        text-align: center;
        display: block;
        width: 100%;
      }
    }
  }
}