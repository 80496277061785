@import 'header', 'modal', 'popup', 'alert', 'verticaltabs', 'horizontaltabs', 'loader', 'hierarchy', 'progresscontrols', 'progress', 'table', 'mobile';
@import 'input', 'toggle', 'checkbox', 'button', 'slider', 'dropdown', 'calendar', 'color';
@import 'map', 'segment', 'viewoptions', 'infosegment', 'error', 'dbmatch';
@import 'carousel';

.regular-button-states {
  &:hover {
    background-color: $hover-background-color;
  }
  &:active {
    background-color: $active-background-color;
  }
}

.disabled {
  cursor: disabled !important;
}

.fill-background {
  fill: $background-dark;
}