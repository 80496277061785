div.notfound-wrapper {
  display: flex;
  height: calc(100vh - 73px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-family: $title-font;
    font-weight: 500;
    font-size: 24px;
    margin: 24px 0;
  }
  p {
    margin: 0;
    font-size: 16px;
    opacity: .8;
  }
}