div.skeleton-outer-wrapper {
  height: 100vh;
  div.skeleton-wrapper {
    display: grid;
    grid-template-columns: 64px auto;
    height: 100%;
    &.tabs-hidden {
      grid-template-columns: auto;
    }
    div.skeleton-content {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 73px);
    }
  }
}
