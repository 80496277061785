
div.alert-outer-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1024;
  padding: 64px 240px;
  display: flex;
  background: rgba($background-dark, .2);
  justify-content: center;
  backdrop-filter: blur(2px);
  visibility: visible;
  transition: opacity 320ms;
  &.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    div.modal-wrapper {
      transform: translateY(120px)
    }

  }
  div.alert-wrapper {
    width: 320px;
    height: fit-content;
    background-color: $background;
    border-radius: 5px;
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    div.header {
      background: $background-dark;
      border-bottom: 1px solid $border-color;
      padding: 12px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      span.title {
        display: block;
        font-family: $title-font;
        font-size: 20px;
        margin-bottom: 12px;
      }
      span.subtitle {
        font-size: 14px;
        opacity: .72;
        font-family: $title-font;
        font-weight: 400;
      }
    }
    div.actions {
      padding: 12px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      gap: 12px;
      justify-content: space-between;
      width: 100%;
      button {
        margin-bottom: 0;
        flex: 1;
      }
    }
  }
}