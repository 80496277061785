div.single-tree-wrapper {
  flex: 1;
  display: flex;
  position: relative;
  div.multi-tab-view-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    z-index: 48;
  }
  div.views {
    flex: 1;
    display: flex;
    div.view-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
      width: 50%;
      &:not(:first-child) {
        border-left: 1px solid $border-color;
      }
      & > div {
        width: 100%;
        height: 100%;
      }
      div.carouselParent {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background: black;
      }
    }
  }
}