div.hierarhy-wrapper {
  user-select: none;
  div.level-outer-wrapper {
    div.level-wrapper {
      display: flex;
      align-items: center;
      font-family: $title-font;
      padding: 4px 12px;
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      div.dot {
        width: 8px;
        height: 8px;
        border-radius: 50px;
        margin-right: 8px;
        transform: translateX(-50%);
        position: relative;
        z-index: 12;
      }
    }
    div.sublevels-wrapper {
      padding-left: 12px;
      position: relative;
    }
  }
}