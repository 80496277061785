div.grid-wrapper {
  display: flex;
  flex: 1;
  max-height: calc(100vh - 72px);
  flex-direction: column;
  div.top-segment {
    flex: 1;
    display: flex;
    min-width: 0;
    overflow: hidden;
    div.main-content {
      flex: 1;
      min-height: 0;
      overflow: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      position: relative;
    }
  }

  div.segment-wrapper {
    box-sizing: border-box;
    position: relative;
    transition: none;
    user-select: none;
    div.handler-wrapper {
      z-index: 149;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        div.handler {
          opacity: 1;
        }
      }
      div.handler {
        opacity: 0;
        width: 100%;
        height: 2px;
        background-color: $primary;
        margin-top: 3px;
      }
      div.handler-arrow {
        width: 12px;
        height: 64px;
        background-color: $primary;
        flex-shrink: 0;
        position: absolute;
        right: 6px;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        opacity: 0;
        animation: blinking 4.8s 0.8s linear forwards;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $background-dark;
        svg {
          width: 8px;
        }
      }
    }
    &.vertical {
      border-top: 1px solid $border-color;
      div.handler-wrapper {
        top: -12px;
        left: 0;
        right: 0;
        position: absolute;
        height: 18px;
        div.handler-arrow {
          right: unset;
          transform: rotate(90deg);
          bottom: -20px;  
        }
        div.handler {
          opacity: 0;
          width: 100%;
          height: 2px;
          background-color: $primary;
          margin-top: 4px;
        }
        &:hover {
          div.handler {
            opacity: 1;
          }
        }
        cursor: row-resize;
      }
    }
    &.horizontal {
      border-left: 1px solid $border-color;
      div.handler-wrapper {
        left: -12px;
        top: 0;
        bottom: 0;
        position: absolute;
        width: 18px;
        cursor: col-resize;
        div.handler {
          width: 2px;
          height: 100%;
          margin-left: 3px;
        }
      }
    }
  }
}

@keyframes blinking {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  88% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}