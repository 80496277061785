@import 'skeleton', 'grid', 'loader';

@import 'singletree';

div.page-wrapper {
  height: calc(100vh - 72px);
  overflow-y: auto;
}

div.uikit-content-wrapper {
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  h1, h2, h3, h4 {
    margin: 0;
    font-family: $title-font;
    font-weight: initial;
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid $border-color;
    &:not(:first-of-type) {
      margin-top: 48px;
    }
  }
  div.loader-segment {
    position: relative;
    height: 240px;
  }
}

div.panoramicview-container {
  border-left: 1px solid $border-color;
}