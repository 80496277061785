div.progress-controls-wrapper {
  display: flex;
  align-items: center;
  border-top: 1px solid $border-color;
  padding: 8px 16px;
  user-select: none;
  &.disabled {
    color: rgba($color, .2) !important;
    pointer-events: none;
  }
  div.progress-wrapper {
    flex: 1;
  }
  div.separator {
    margin: 0 12px;
    height: 42px;
    width: 1px;
    background-color: $border-color;
  }
  div.actions-wrapper {
    display: flex;
    margin-left: 12px;
    padding-left: 12px;
    border-left: 1px solid $border-color;
    div.action-wrapper {
      padding: 12px;
      cursor: pointer;
      border-radius: 4px;
      &.disabled {
        opacity: .4;
        pointer-events: none;
      }
      &:hover {
        background-color: $background-dark;
        &.red {
          color: $red;
        }
        &.green {
          color: $primary-green;
        }
        &.primary {
          color: $primary;
        }
      }
      &:active {
        background-color: $active-background-color;
      }
      span {
        margin-left: 8px;
      }
    }
  }
}