div.dropdown-input-wrapper {
  position: relative;
  width: 320px;
  max-width: 100%;
  margin-bottom: 32px;
  div.options-dropdown-wrapper {
    max-height: 240px;
    border-radius: 4px;
    overflow: auto;
    border: 1px solid $border-color;
    background: $background-dark;
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    right: 0;
    z-index: 24;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-12px);
    &.visible {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }
    div.option-wrapper {
      padding: 12px 16px;
      cursor: pointer;
      user-select: none;
      &.active {
        color: $primary;
      }
      &:hover {
        background-color: darken($background-dark, 3%);
      }
      &:active {
        background-color: darken($background-dark, 8%);
      }
      span {
        font-family: $title-font;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  div.dropdown-content-wrapper {
    height: 48px;
    background-color: $background-dark;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: 320ms;
    box-shadow: 0 0 0 1px $border-color, 0 0 0 0px rgba($primary, 0);
    margin-bottom: 12px;
    position: relative;
    cursor: pointer;
    &.error {
      box-shadow: 0 0 0 1px $red, 0 0 0 0px rgba($primary, 0);
      span.alert {
        color: $red;
      }
    }
    &.warning {
      box-shadow: 0 0 0 1px $orange, 0 0 0 0px rgba($primary, 0);
      span.alert {
        color: $orange;
      }
    }
    &.disabled {
      pointer-events: none;
      svg, input {
        opacity: .4;
      }
    }
    &.focused {
      // background-color: transparent;
      box-shadow: 0 0 0 1px rgba($primary, 1), 0 0 0 4px rgba($primary, .4);
      div.arrow {
        transform: translateY(4px);
      }
    }
    &.icon {
      input {
        padding-left: 44px;
      }
    }
    div.arrow {
      position: absolute;
      right: 12px;
      transform: translateY(-4px);
    }
    svg {
      margin: 0 12px;
      pointer-events: none;
      width: 20px;
      height: 20px;
      margin-right: 0;
    }
    span {
      background: none;
      outline: none;
      border: none;
      font-size: 16px;
      color: $color;
      font-family: $title-font;
      padding-left: 12px;
      opacity: 0.4;
      &.selected {
        opacity: 1;
      }
    }
    span.alert {
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
      padding: 6px 12px;
      font-family: $title-font;
      font-size: 14px;
    }
  }
}