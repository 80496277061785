div.tab-view-wrapper {
  display: flex;
  background: $background-dark;
  border-bottom: 1px solid $border-color;
  user-select: none;
  div.tab-wrapper {
    padding: 12px 24px;
    cursor: pointer;
    position: relative;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    font-family: $title-font;
    text-transform: uppercase;
    &::after {
      bottom: -1px;
      left: 0;
      right: 0;
      display: block;
      position: absolute;
      content: '';
      background-color: $primary;
      height: 3px;
      opacity: 0;
      pointer-events: none;
      transition: 120ms;
    }
    &.active {
      background: $background;
      color: $primary;
      &::after {
        opacity: 1;
      }
    }
    &.disabled {
      opacity: .32;
      pointer-events: none;
    }
  }
}

div.multi-tab-view-wrapper {
  display: flex;
  background: $background-dark;
  div.tab-view-wrapper:not(:last-child) {
    // &::after {
    //   width: 8px;
    //   content: '';
    //   display: '';
    // }
    border-right: 1px solid $border-color;
  }
}