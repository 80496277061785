div.finished-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;right: 0;
  z-index: 1024;
  background: rgba($background, .9);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-left: 1px solid $border-color;
}