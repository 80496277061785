div.modal-outer-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1024;
  padding: 120px 240px;
  display: flex;
  background: rgba(darken($background-dark, 5%), .8);
  justify-content: center;
  backdrop-filter: blur(4px);
  visibility: visible;
  transition: opacity 320ms;
  &.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    div.modal-wrapper {
      transform: translateY(120px)
    }

  }
  div.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  div.modal-wrapper {
    max-width: calc(100vw - 96px);
    width: 560px;
    background: $background;
    transition: transform 320ms, opacity 320ms;
    transform: translateY(0);
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 0 32px 32px rgba(black, .04);
    display: flex;
    flex-direction: column;
    div.content-wrapper {
      flex: 1;
      display: flex;
      overflow: auto;
      padding: 24px;
      & > div {
        flex: 1;
      }
      div.toggle-input-wrapper, div.text-input-wrapper, div.checkbox-input-wrapper, div.color-input-wrapper {
        width: calc(100% - 24px);
        margin-right: 0;
      }
      div.input-wrapper {
        width: 100%;
      }
      button {
        width: 100%;
      }
    }
    h2 {
      margin: 0;
      margin-top: 24px;
      margin-left: 12px;
      margin-bottom: 12px;
      font-family: $title-font;
      font-size: 14px;
      text-transform: uppercase;
      opacity: .4;
      font-weight: 500;
    }
    h1 {
      font-family: $title-font;
      font-size: 16px;
      font-weight: 500;
      padding-left: 12px;
      margin-top: 12px;
      margin-bottom: 12px;
      &:not(:first-child) {
        border-top: 1px solid $border-color;
        padding-top: 12px;
      }
    }
    div.header {
      padding: 24px;
      text-align: center;
      font-weight: 500;
      font-family: $title-font;
      font-size: 20px;
      justify-content: center;
      align-items: center;
      display: flex;
      position: relative;
      border-bottom: 1px solid $border-color;
      background-color: $background-dark;
      div.close-wrapper {
        position: absolute;
        right: 12px;
        cursor: pointer;
        padding: 12px;
        font-size: 14px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        &:hover {
          background-color: $hover-background-color;
        }
        &:active {
          background-color: $active-background-color;
        }
      }
    }
  }
}