div.las-viewer-wrapper {
  background: rgba($background-dark, .4);
  display: flex;
  justify-content: center;
  align-items: center;
}

// Location validation
div.location-validation-wrapper {
  display: grid;
  flex: 1;
  grid-template-rows: 1fr 64px;
  div.viewers {
    display: flex;
    flex: 1;
    div.panorama-view-wrapper { 
      display: flex;
      flex: 1;
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
    }
    div.vertical-stack {
      flex: 1;
      display: flex;
      height: calc(100vh - 73px - 64px);
      border-left: 1px solid $border-color;
      div.tree-mover-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        div.tree-mover, canvas {
          height: calc((100vh - 73px - 64px) / 2);
          &:first-child {
            border-bottom: 1px solid $border-color;
          }
        }
      }
      // div.las-viewer-wrapper {
      //   border-left: 1px solid $border-color;
      //   &:first-child {
      //     border-bottom: 1px solid $border-color;
      //   }
      // }
    }
  }
}

// Semantics Editor
div.semantics-editor-wrapper {
  height: calc(100vh - 73px);
  display: grid;
  grid-template-rows: auto 64px;
  div.viewers {
    display: flex;
    height: calc(100vh - 73px - 64px);
  }
  div.tree-mover {
    border-left: 1px solid $border-color;
  }
  canvas {
    width: 100%;
    height: 100%;
  }
}



// DB Matching
div.database-matching-wrapper {
  flex: 1;
  display: grid;
  grid-template-rows: 1fr 228px 64px;
}
div.database-matching-wrapper.mavens {
  grid-template-rows: 1fr 64px;
}

div.location-actions-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 56px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  div.action-wrapper {
    pointer-events: all;
  }
}

div.validation-overview-wrapper {
  flex: 1;
  display: flex;
  position: relative;
  div.panorama-map-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $background-dark;
    z-index: 48;
    display: flex;
  }
}